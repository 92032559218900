import React from 'react';
import apphome from "../assets/Floating Duck/App/Product Discription.jpg";
import applanding from "../assets/Floating Duck/App/Registration.jpg";
import appdescription from "../assets/Floating Duck/App/Order Confirmation.jpg";
import { Link } from 'react-router-dom';

const BannerApp = () => {
  return (
    <div className="bg-gray-100 flex justify-center items-center p-5 py-10 shadow-lg rounded-2xl">
      <div className="max-w-5xl flex flex-col lg:flex-row items-center lg:items-start space-y-8 lg:space-y-0 lg:space-x-10 px-6 lg:px-0">
        {/* Left Section */}
        <div className="text-center lg:text-left w-full lg:w-1/2">
          <h4 className="uppercase text-sm text-gray-600 font-semibold mb-2">
            E-Commerce Applications
          </h4>
          <h1 className="text-4xl lg:text-4xl font-semibold text-gray-900">
            Launch Your <span className="text-cyan-500">E-Commerce App</span> Effortlessly.
          </h1>
          <p className="mt-4 text-gray-500">
            Leverage cutting-edge tools to build a customized e-commerce app that suits your business needs. Enhance user experience and drive conversions with tailored features.
          </p>
          {/* Button */}
          <Link to={'/services'}>
            <button className="mt-6 bg-black text-white font-800 py-3 px-6 rounded-md shadow-md hover:bg-black transition">
              Explore Services
            </button>
          </Link>
        </div>

        {/* Right Section */}
        <div className="flex flex-col lg:flex-row w-full lg:w-1/2 space-y-4 lg:space-y-0 lg:space-x-4">
          {/* Grid of three images */}
          <div className="grid grid-cols-2 gap-4 lg:grid-cols-3 w-full">
            <img
              src={applanding}
              alt="User Registration Screen"
              className="rounded-xl shadow-lg w-full h-auto object-cover"
            />
            <img
              src={apphome}
              alt="Product Listing and Description Screen"
              className="rounded-xl shadow-lg w-full h-auto object-cover"
            />
            <img
              src={appdescription}
              alt="Order Confirmation Screen"
              className="rounded-xl shadow-lg w-full h-auto object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerApp;
