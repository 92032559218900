import React from 'react';
import Navbar from '../constants/Navbar';
import Footer from '../constants/Footer';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faDesktop, faPaintBrush, faMobileAlt, faPalette, 
    faChartBar, faRobot, faCloud, faServer 
} from '@fortawesome/free-solid-svg-icons';
import ProgressSlider from '../constants/ProgressSlider';

const Careers = () => {
    const navigate = useNavigate();

    const iconColors = {
        'Frontend Developer': 'text-blue-500',
        'UI/UX Designer': 'text-green-500',
        'Mobile App Developer': 'text-purple-500',
        'Graphic Designer': 'text-pink-500',
        'Data Analyst': 'text-yellow-500',
        'Machine Learning Engineer': 'text-orange-500',
        'Cloud Engineer': 'text-teal-500',
        'Backend Developer': 'text-red-500',
    };

    const careerOpportunities = [
        {
            id: 'FRNTDEV001',
            title: 'Frontend Developer',
            description: 'Develop modern and responsive interfaces using React and Tailwind CSS.',
            skills: ['React.js', 'JavaScript/ES6+', 'Tailwind CSS', 'Git/GitHub'],
            benefits: ['Remote work', 'Competitive salary', 'Career growth programs'],
            level: 'Intermediate to Advanced',
            icon: faDesktop,
        },
        {
            id: 'UIUXDES002',
            title: 'UI/UX Designer',
            description: 'Design beautiful user interfaces and provide seamless user experiences.',
            skills: ['Figma/Adobe XD', 'Wireframing', 'User Research', 'Responsive Design'],
            benefits: ['Creative freedom', 'Remote working', 'Collaborative environment'],
            level: 'Beginner to Intermediate',
            icon: faPaintBrush,
        },
        {
            id: 'MOBDEV003',
            title: 'Mobile App Developer',
            description: 'Develop cross-platform mobile apps for Android and iOS.',
            skills: ['Flutter/React Native', 'API Integration', 'Optimization', 'ASO'],
            benefits: ['Flexible hours', 'Exciting projects', 'Training resources'],
            level: 'Intermediate',
            icon: faMobileAlt,
        },
        {
            id: 'BACKDEV004',
            title: 'Backend Developer',
            description: 'Build robust server-side applications and manage APIs efficiently.',
            skills: ['Node.js/Express', 'RESTful APIs', 'Database Management (SQL/NoSQL)', 'Authentication & Security'],
            benefits: ['Remote-friendly', 'Opportunity to work with microservices', 'Competitive salary'],
            level: 'Intermediate to Advanced',
            icon: faServer,
        },
        {
            id: 'GRAPHDS005',
            title: 'Graphic Designer',
            description: 'Create stunning visuals and design solutions for branding.',
            skills: ['Photoshop', 'Illustrator', 'Logo Design', 'Typography'],
            benefits: ['Portfolio projects', 'Bonuses for outstanding work', 'Networking opportunities'],
            level: 'All Levels',
            icon: faPalette,
        },
        {
            id: 'DATAANL006',
            title: 'Data Analyst',
            description: 'Analyze data to generate insights for decision-making.',
            skills: ['Excel/Power BI', 'SQL', 'Data Visualization', 'Statistical Analysis'],
            benefits: ['Remote work', 'Continuous learning', 'Competitive salary'],
            level: 'Intermediate to Advanced',
            icon: faChartBar,
        },
        {
            id: 'MLEENG007',
            title: 'Machine Learning Engineer',
            description: 'Build and deploy ML models for predictive analysis.',
            skills: ['Python', 'TensorFlow', 'Model Deployment', 'Data Preprocessing'],
            benefits: ['Access to AI tools', 'Research opportunities', 'Flexible schedule'],
            level: 'Advanced',
            icon: faRobot,
        },
        {
            id: 'CLDENG008',
            title: 'Cloud Engineer',
            description: 'Manage cloud infrastructure and optimize performance.',
            skills: ['AWS/Azure', 'IaC', 'Docker/Kubernetes', 'Cloud Security'],
            benefits: ['Scalable systems', 'Certifications', 'Hybrid working options'],
            level: 'Intermediate to Advanced',
            icon: faCloud,
        },
    ];

    return (
        <>
            <Navbar className="mb-10" />
            <div className="py-16 mt-10 selection:bg-cyan-200 selection:text-cyan-900 m-5 p-5 bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)]">
                <div className="relative">
                    <div className="h-64 bg-gradient-to-r from-[#fcf5e5] to-[#e0f7fa] rounded-xl shadow-lg flex items-center justify-center p-5 ">
                        <div className="text-center text-cyan-600 p-5">
                            <h1 className="text-5xl font-extrabold">Join Our Team</h1>
                            <p className="mt-2 text-xl">Work with passionate professionals and make an impact.</p>
                        </div>
                    </div>
                </div>

                <div className="py-12 px-6 md:px-16 lg:px-32">
                    <h2 className="text-4xl font-bold text-center mb-10">Current Openings</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {careerOpportunities.map((role) => (
                            <div
                                key={role.id}
                                className="p-6 bg-white rounded-2xl shadow-lg"
                            >
                                <div className="flex items-center gap-4 mb-4">
                                    <FontAwesomeIcon
                                        icon={role.icon}
                                        className={`text-4xl ${iconColors[role.title]}`}
                                    />
                                    <h3 className="text-2xl font-semibold">{role.title}</h3>
                                </div>
                                <p className="text-gray-600 mb-4">{role.description}</p>

                                <div className="mb-3">
                                    <h4 className="text-lg font-semibold">Required Skills:</h4>
                                    <ul className="list-disc list-inside text-gray-700 mt-2 space-y-1">
                                        {role.skills.map((skill, i) => (
                                            <li key={i}>{skill}</li>
                                        ))}
                                    </ul>
                                </div>

                                <div className="mb-3">
                                    <h4 className="text-lg font-semibold">Benefits:</h4>
                                    <ul className="list-disc list-inside text-gray-700 mt-2 space-y-1">
                                        {role.benefits.map((benefit, i) => (
                                            <li key={i}>{benefit}</li>
                                        ))}
                                    </ul>
                                </div>

                                <p className="text-sm text-gray-500 mb-4">
                                    Experience Level: {role.level}
                                </p>

                                <p className="text-sm text-gray-700 text-center font-bold mb-4">
                                    Job ID: {role.id}
                                </p>

                                <button
                                    className="w-full py-2 bg-black text-white font-600 rounded-md hover:bg-grey-500 transition"
                                    onClick={() => navigate('/stay-tuned')} // Change the path here
                                >
                                    Apply Now
                                </button>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="text-center py-12 bg-gradient-to-r from-[#fcf5e5] to-[#e0f7fa] p-5 rounded-xl shadow-md mx-6 md:mx-16 lg:mx-32 mt-12">
                    <h2 className="text-3xl font-bold mb-4">Why Work With Us?</h2>
                    <p className="text-lg text-gray-600 max-w-2xl mx-auto mb-6 p-5">
                        At Nexkites, we believe in innovation, creativity, and continuous learning. Join us to grow your career and make an impact.
                    </p>
                    <button
                        className="px-6 py-3 bg-black font-600 text-white rounded-md hover:bg-grey-500 transition"
                        onClick={() => navigate('/about-us')}
                    >
                        Learn More About Us
                    </button>
                </div>
            </div>
            <ProgressSlider />
            <Footer />
        </>
    );
};

export default Careers;
