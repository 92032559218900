import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faMobileAlt, faPaintBrush, faChartLine, faCloud, faVideo, faDatabase, faRobot, faImage } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../constants/Navbar';
import Footer from '../constants/Footer';
import { useNavigate } from 'react-router-dom';
import ProgressSlider from '../constants/ProgressSlider';

const ProductsSection = () => {
    const navigate = useNavigate();

    const products = [
        {
            title: 'Websites',
            icon: faDesktop,
            description: 'Get your business online with a responsive, SEO-friendly website.',
            features: ['Responsive web design', 'SEO-optimized structure', 'Custom domain setup', 'CMS'],
            color: 'text-blue-500',
        },
        {
            title: 'UI/UX Designing',
            icon: faPaintBrush,
            description: 'Design interfaces that users love.',
            features: ['Wireframing', 'User testing', 'Responsive design', 'Prototyping'],
            color: 'text-green-500',
        },
        {
            title: 'Mobile Applications',
            icon: faMobileAlt,
            description: 'Develop apps for both Android and iOS.',
            features: ['Native apps', 'Performance testing', 'Post-launch support', 'ASO'],
            color: 'text-red-500',
        },
        {
            title: 'Backend Development',
            icon: faDatabase,
            description: 'Build and maintain powerful server-side applications.',
            features: ['API development', 'Database management', 'Authentication & Security', 'Microservices'],
            color: 'text-yellow-500',
        },
        {
            title: 'Video & Animations',
            icon: faVideo,
            description: 'Create engaging video content and animations.',
            features: ['Explainer videos', '2D/3D animations', 'Storyboarding', 'Motion graphics'],
            color: 'text-purple-500',
        },
        {
            title: 'Graphic Design',
            icon: faImage,
            description: 'Elevate your brand with custom designs.',
            features: ['Branding & logos', 'Marketing materials', 'Social media graphics', 'Illustrations'],
            color: 'text-pink-500',
        },
        {
            title: 'Data Analytics',
            icon: faChartLine,
            description: 'Gain insights from data and drive business decisions.',
            features: ['Data visualization', 'Predictive analytics', 'Dashboards', 'Report generation'],
            color: 'text-indigo-500',
        },
        {
            title: 'Machine Learning',
            icon: faRobot,
            description: 'Leverage AI to enhance business processes.',
            features: ['Model training', 'Data preprocessing', 'ML deployment', 'Performance evaluation'],
            color: 'text-teal-500',
        },
        {
            title: 'Cloud Engineering',
            icon: faCloud,
            description: 'Optimize and manage cloud infrastructure.',
            features: ['Cloud setup (AWS/Azure)', 'IaC tools', 'Docker & Kubernetes', 'Cloud security'],
            color: 'text-gray-500',
        },
    ];

    return (
        <>
            <Navbar className="mb-10" />
            <div className="py-12 px-4 mt-10 sm:px-6 lg:px-8 xl:px-10 bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)] selection:bg-cyan-300 selection:text-cyan-900">
                {/* Heading */}
                <div className="text-center mb-12">
                    <h1 className="text-3xl md:text-4xl font-bold text-gray-900">
                        Our Products
                    </h1>
                    <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
                        We offer a diverse range of services tailored to meet the needs of businesses of all sizes.
                    </p>
                </div>

                {/* Products Grid */}
                <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {products.map((product, index) => (
                        <div
                            key={index}
                            className="bg-white p-6 rounded-xl shadow-lg flex flex-col"
                        >
                            {/* Icon Section */}
                            <div className="h-32 flex justify-center items-center">
                                <FontAwesomeIcon icon={product.icon} className={`text-6xl ${product.color}`} />
                            </div>
                            {/* Title Section */}
                            <h3 className="text-2xl font-semibold mt-4 text-center text-gray-800">
                                {product.title}
                            </h3>
                            {/* Description Section */}
                            <p className="mt-2 text-gray-600 text-center">
                                {product.description}
                            </p>
                            {/* Features List Section */}
                            <ul className="mt-4 space-y-2 text-gray-700 text-sm list-disc list-inside">
                                {product.features.map((feature, i) => (
                                    <li key={i}>{feature}</li>
                                ))}
                            </ul>
                            {/* Button Section */}
                            <div className="mt-6 flex justify-center">
                                <button
                                    className="py-2 px-6 bg-black text-white font-600 rounded-md hover:bg-black transition w-full sm:w-auto"
                                    onClick={() => navigate('/pricing')}
                                >
                                    Explore
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <ProgressSlider/>
            <Footer />
        </>
    );
};

export default ProductsSection;
