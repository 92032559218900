import React from 'react';
import FooterLogo from "../assets/logo/Nexkites-kite.png";



const Footer = () => {
    return (
        <footer className="text-gray-700 py-12 text-center selection:bg-cyan-300 selection:text-cyan-900 p-5">
            {/* Horizontal Line Divider at the Start */}
            <div className="container mx-auto my-3 border border-grey-500" style={{ width: '%' }}></div>

            {/* Top Section: Logo with Motto and Categories */}
            <div className="container mx-auto grid grid-cols-2 md:grid-cols-4 gap-8 text-sm">

                {/* Left Section: Logo with Motto */}
                <div className="col-span-2 md:col-span-1 flex flex-col left-[10px] items-start">
                    <img
                        src={FooterLogo}
                        alt="NexKites Logo"
                        className="h-12 mb-4"
                    />
                    <p className="text-gray-500 text-sm">
                        Let’s build tomorrow’s web solutions today because your brand deserves the best!
                    </p>
                </div>

                {/* Business Solutions Column */}
                <div>
                    <h4 className="font-bold text-lg mb-4 text-gray-700">Business Solutions</h4>
                    <ul className="space-y-2 text-gray-400">
                        <li>Cloud Storage Facility</li>
                        <li>Project Management</li>
                        <li>Content Marketing</li>
                        <li>Contact Sales</li>
                    </ul>
                </div>

                {/* Company Column */}
                <div>
                    <h4 className="font-bold text-lg mb-4 text-gray-700">Services</h4>
                    <ul className="space-y-2 text-gray-400">
                        <li>Graphics & Design</li>
                        <li>Web Development</li>
                        <li>Mobile Applications</li>
                        <li>End-to-End Projects</li>
                        <li>Service Catalog</li>
                    </ul>
                </div>

                {/* Categories Column (Right-Aligned) */}
                <div className="md:col-span-1">
                    <h4 className="font-bold text-lg mb-4 text-gray-700">Company</h4>
                    <ul className="space-y-2 text-gray-400">
                        <li>About Nexkites</li>
                        <li>Careers</li>
                        <li>Help & Support</li>
                        <li>Terms of Service</li>
                        <li>Privacy Policy</li>
                    </ul>
                </div>
            </div>
            

            {/* Horizontal Line Divider before the Footer Bottom Section */}
            <div className="container mx-auto my-3 border border-grey-500" style={{ width: '95%' }}></div>

            {/* Footer Bottom Section */}
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4 md:px-8">
                {/* Left Section: Copyright */}
                <div className="flex items-center space-x-2 text-gray-500 mb-4 md:mb-0">
                    <span className="text-sm text-bold text-grey-500">&copy;2024 NexKites.</span>
                </div>

                {/* Right Section: Social Icons */}
                <div className="flex items-center space-x-4 text-[20px] text-gray-600">
                    <a href="https://x.com/nexkites/" aria-label="Twitter" className="hover:text-cyan-500">
                        <i className="fab fa-x-twitter"></i>
                    </a>
                    <a href="https://www.instagram.com/nexkites/profilecard/?igsh=MXY3aHV4aXBqYmV6Mw==" aria-label="Instagram" className="hover:text-cyan-500">
                        <i className="fab fa-instagram"></i>
                    </a>    
                    <a href="https://www.facebook.com/share/19J1f8JaNK" aria-label="Meta" className="hover:text-cyan-500">
                        <i className="fab fa-meta"></i>
                    </a>
                    <a href="https://www.threads.net/@nexkites?invite=4" aria-label="Thread" className="hover:text-cyan-500">
                        <i className="fab fa-threads"></i>
                    </a>
                    <a href="#" aria-label="Whatsapp" className="hover:text-cyan-500">
                        <i className="fab fa-whatsapp"></i>
                    </a>
                    <span>  </span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
