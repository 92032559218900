import React, { useState } from 'react';
import contactimg from "../assets/logo/Nexkites-kite.png";
import Footer from '../constants/Footer';
import Navbar from '../constants/Navbar';
import ProgressSlider from '../constants/ProgressSlider';

const services = [
    'Product Design',
    'Website Development',
    'Mobile Apps Development',
    'UI/UX Design',
    'Video & Animations',
];

const ContactSection = () => {
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        phone: "",
        address: "",
        projectDetails: "",
    });

    const [selectedServices, setSelectedServices] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const toggleService = (service) => {
        setSelectedServices((prev) =>
            prev.includes(service)
                ? prev.filter((item) => item !== service)
                : [...prev, service]
        );
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prev) => ({ ...prev, [name]: value }));
    };

    const submitData = async (event) => {
        event.preventDefault();
        const { name, email, phone, address, projectDetails } = userData;

        // Validate if all fields are filled and at least one service is selected
        if (!name || !email || !phone || !address || !projectDetails) {
            alert("Please fill all the fields.");
            return;
        }

        if (selectedServices.length === 0) {
            alert("Please select at least one service.");
            return;
        }

        try {
            const res = await fetch(
                'https://nexkites-38efb-default-rtdb.firebaseio.com/userDataRecords.json',
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        name,
                        email,
                        phone,
                        address,
                        projectDetails,
                        services: selectedServices,
                    }),
                }
            );

            if (res.ok) {
                setUserData({
                    name: "",
                    email: "",
                    phone: "",
                    address: "",
                    projectDetails: "",
                });
                setSelectedServices([]);
                setShowSuccessModal(true);
            } else {
                alert("Failed to store data.");
            }
        } catch (error) {
            console.error("Error submitting data:", error);
            alert("An error occurred while submitting your data.");
        }
    };

    const closeModal = () => {
        setShowSuccessModal(false);
    };

    return (
        <>
            <Navbar className="mb-10" />

            <section className="mt-10 bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)] py-10 px-4 sm:px-8 md:px-12 lg:px-16 flex flex-col md:flex-row justify-between items-center gap-8 mb-8 rounded-3xl shadow-xl selection:bg-cyan-300 selection:text-cyan-900">
                {/* Left Section */}
                <div className="w-full md:w-1/2 space-y-4">
                    <h3 className="text-3xl md:text-4xl font-bold text-gray-900 text-center p-5 leading-tight">
                        Contact Us
                    </h3>
                    <p className="text-lg text-gray-600">
                        Let us know how we can help with your next project!
                    </p>

                    <div className=" p-5 rounded-xl">
                        <h3 className="text-xl font-semibold text-gray-800 mb-4">Inquiry for Services</h3>
                        <p className='text-red-400 p-5'>Note: Select Services and fill your details in form</p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                            {services.map((service) => (
                                <button
                                    key={service}
                                    onClick={() => toggleService(service)}
                                    className={`w-full px-4 py-2 text-sm font-semibold rounded-lg transition-all shadow-lg
                                        ${selectedServices.includes(service)
                                            ? 'bg-cyan-500 text-white border-transparent'
                                            : 'bg-white text-gray-700 border-2 border-gray-300 hover:bg-cyan-100'}`}
                                >
                                    {service}
                                </button>
                            ))}
                        </div>
                    </div>

                    <form className="space-y-2 mt-1 p-5 rounded-lg " onSubmit={submitData}>
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={userData.name}
                            onChange={handleInputChange}
                            required
                            className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-400"
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={userData.email}
                            onChange={handleInputChange}
                            required
                            className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-400"
                        />
                        <input
                            type="tel"
                            name="phone"
                            placeholder="Your Phone"
                            value={userData.phone}
                            onChange={handleInputChange}
                            required
                            className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-400"
                        />
                        <input
                            type="text"
                            name="address"
                            placeholder="Your Address"
                            value={userData.address}
                            onChange={handleInputChange}
                            required
                            className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-400"
                        />
                        <textarea
                            name="projectDetails"
                            placeholder="Details of Your Project"
                            value={userData.projectDetails}
                            onChange={handleInputChange}
                            required
                            className="w-full px-4 py-3 rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-400 resize-none"
                            rows={4}
                        ></textarea>

                        <button
                            type="submit"
                            className="w-full py-3 bg-black text-white font-800 rounded-lg shadow-lg hover:bg-gray-700 transition-all"
                        >
                            Send Message
                        </button>
                    </form>
                </div>

                {/* Right Section (Image Centered on Top with Content Below) */}
                <div className="w-full md:w-1/2 flex flex-col justify-center items-center text-center space-y-6">
                    <img
                        src={contactimg}
                        alt="Contact us"
                        className="w-[100px] sm:w-[150px] md:w-[250px] lg:w-[250px] mb-8"
                    />
                    <h2 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold text-gray-800">
                        Got a Project? Let's Talk!
                    </h2>
                </div>
            </section>

            {/* Success Modal */}
            {showSuccessModal && (
                <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full">
                        <h2 className="text-2xl font-800 text-center text-green-600">
                            Success!
                        </h2>
                        <p className="text-center text-gray-700 mt-4">
                            Your message has been sent successfully.
                        </p>
                        <div className="mt-6 flex justify-center">
                            <button
                                onClick={closeModal}
                                className="px-6 py-2 bg-black text-white font-800 rounded-lg shadow-md hover:bg-black transition"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <ProgressSlider />
            <Footer />
        </>
    );
};

export default ContactSection;
