import React from "react";
import aboutimg from "../assets/logo/Nexkites-kite.png";
import Footer from "../constants/Footer";
import Navbar from "../constants/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory, faBullseye, faHandsHelping, faRocket } from "@fortawesome/free-solid-svg-icons";
import "../index.css";
import ProgressSlider from "../constants/ProgressSlider";

const About = () => {
    const values = [
        {
            title: "Innovation",
            description: "We constantly innovate to build solutions that are not only current but also future-ready.",
            icon: faRocket,
            bgColor: "bg-blue-50",
            iconColor: "text-blue-600",
        },
        {
            title: "Quality",
            description: "We deliver high-quality solutions that are designed with attention to detail and excellence.",
            icon: faBullseye,
            bgColor: "bg-green-50",
            iconColor: "text-green-600",
        },
        {
            title: "Collaboration",
            description: "Collaboration with clients and within our teams is at the heart of everything we do.",
            icon: faHandsHelping,
            bgColor: "bg-yellow-50",
            iconColor: "text-yellow-600",
        },
        {
            title: "Velocity",
            description: "We prioritize swift delivery while maintaining the highest standards of quality and service.",
            icon: faHistory,
            bgColor: "bg-purple-50",
            iconColor: "text-purple-600",
        },
    ];

    return (
        <>
            <Navbar />

            {/* Page Content */}
            <div className="container mx-auto py-16 px-4 md:px-8 lg:px-16 mt-12 md:mt-16 bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)] flex flex-col items-center selection:bg-cyan-300 selection:text-cyan-900">

                {/* Main Heading */}
                <h1 className="text-center text-3xl md:text-4xl font-bold text-gray-900 mb-12 tracking-tight">
                    About Us
                </h1>

                {/* Main Section - Image and Introduction */}
                <div className="flex flex-col md:flex-row items-center justify-center bg-peach-100 gap-6 md:gap-10 p-6 rounded-2xl shadow-lg">
                    {/* Image Section */}
                    <div className="w-full md:w-1/2 flex justify-center">
                        <img
                            src={aboutimg}
                            alt="About Nexkites"
                            className="w-72 h-72 md:w-80 md:h-80 "
                        />
                    </div>

                    {/* Text Section */}
                    <div className="w-full md:w-1/2 text-center md:text-left">
                        <h2 className="text-xl md:text-2xl font-normal text-gray-800 leading-relaxed mb-6">
                            At Nexkites, we craft cutting-edge websites and mobile apps tailored to our clients' needs, harnessing industry-leading tools and talented teams to deliver transformative digital solutions.
                        </h2>
                        <p className="text-gray-500 mb-2">— Team Nexkites</p>
                    </div>
                </div>

                {/* Our Story Section */}
                <div className="flex flex-col md:flex-row items-center bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)] justify-center bg-peach-100 mt-16 gap-6 md:gap-10 p-6 rounded-2xl shadow-lg">
                    <div className="w-full md:w-1/2 text-center">
                        <h2 className="text-2xl md:text-3xl font-semibold text-gray-600 mb-5">
                            Our Story
                        </h2>
                        <p className="text-gray-600 leading-relaxed text-lg">
                            Founded in Oct-2024, Nexkites emerged with a vision to revolutionize the digital landscape. Our journey began with a passion for innovation, and today, we’re proud to serve clients globally, turning ideas into reality.
                        </p>
                    </div>
                </div>

                {/* Mission Section */}
                <div className="flex flex-col md:flex-row bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)] items-center justify-center bg-peach-100 mt-16 gap-6 md:gap-10 p-6 rounded-2xl shadow-lg">
                    <div className="w-full md:w-1/2 text-center">
                        <h2 className="text-2xl md:text-3xl font-semibold text-gray-600 mb-5">
                            Our Mission
                        </h2>
                        <p className="text-gray-600 leading-relaxed text-lg">
                            Our mission is to empower businesses by creating high-performing digital products that provide exceptional user experiences, drive engagement, and grow brands.
                        </p>
                    </div>
                </div>

                {/* Core Values Section */}
                <div className="flex flex-col items-center bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)] justify-center mt-16 gap-6 md:gap-10 p-5">
                    <h2 className="text-2xl md:text-3xl font-semibold text-gray-600 mb-5">
                        Core Values
                    </h2>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 px-4 md:px-6">
                        {values.map((value, index) => (
                            <div
                                key={index}
                                className={`flex flex-col items-center text-center p-6 shadow-lg rounded-lg ${value.bgColor}`}
                            >
                                <FontAwesomeIcon
                                    icon={value.icon}
                                    className={`text-4xl mb-4 ${value.iconColor}`}
                                />
                                <h3 className="text-xl font-semibold mb-2 text-gray-600">{value.title}</h3>
                                <p className="text-gray-600">{value.description}</p>
                            </div>
                        ))}
                    </div>
                </div>


                {/* Call to Action Section */}
                <div className="bg-gradient-to-r from-[#fcf5e5] to-[#e0f7fa] py-10 mt-16 rounded-2xl shadow-lg text-center p-5">
                    <h1 className="text-gray-600 text-3xl sm:text-4xl md:text-5xl font-bold p-2">
                        Ready To{' '}
                        <span className="text-cyan-500">Work</span>
                        <span> Together?</span>
                    </h1>
                    <p className="text-gray-600 mb-5 text-lg">
                        Let’s bring your vision to life. Contact us today to discuss your project!
                    </p>
                    <a href="/support" className="bg-black font-600 text-white px-4 py-2 rounded-lg hover:bg-black transition duration-100">
                        Contact Us
                    </a>
                </div>
            </div>
            <ProgressSlider/>   
            <Footer />
        </>
    );
};

export default About;
