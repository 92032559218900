import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faKey, faUser, faCog } from '@fortawesome/free-solid-svg-icons';
import BMimg from "../assets/abnvk/abnvk.png";
import { Link } from 'react-router-dom';

const Bannerabnvk = () => {
  return (
    <div className="relative bg-peach-200 p-6 md:p-10 my-6 md:my-10 rounded-2xl shadow-lg flex flex-col md:flex-row items-center mx-auto gap-6 md:gap-3">
      {/* Right Section - Image */}
      <div className="w-full md:w-1/2 relative">
        {/* Background Shapes */}
        <div className="absolute inset-0 flex justify-end">
          <div className="bg-[Purple] w-1/4 h-full rounded-r-xl"></div>
          <div className="bg-[#FAF1DC] w-3/4 h-full rounded-r-[50px]"></div>
        </div>

        {/* Image Placeholder */}
        <div className="relative z-10 p-4">
          <img
            src={BMimg}
            alt="Educational Trust Illustration"
            className="rounded-xl object-cover shadow-lg w-full h-full"
          />
        </div>
      </div>

      {/* Left Section - Text */}
      <div className="w-full md:w-1/2">
        <h4 className="uppercase text-sm text-gray-600 font-semibold font-sans">Education Made Accessible</h4>
        <h1 className="text-3xl md:text-4xl font-semibold text-gray-800 mt-2 font-sans">
          Empowering Students, Building Futures.
        </h1>
        <p className="text-gray-600 mt-4 font-sans">
          The most secure and reliable platform to manage your educational initiatives.
        </p>

        {/* Features */}
        <ul className="mt-4 space-y-2 text-gray-600 font-sans">
          <li className="flex items-center">
            <FontAwesomeIcon icon={faLock} className="mr-2" /> Data Security & Privacy
          </li>
          <li className="flex items-center">
            <FontAwesomeIcon icon={faKey} className="mr-2" /> Easy Access for All Stakeholders
          </li>
          <li className="flex items-center">
            <FontAwesomeIcon icon={faUser} className="mr-2" /> Centralized Registration Management
          </li>
          <li className="flex items-center">
            <FontAwesomeIcon icon={faCog} className="mr-2" /> Customizable Administrative Control
          </li>
        </ul>

        {/* Button */}
        <Link to="/services">
          <button className="mt-6 px-5 py-3 bg-black text-white font-300 rounded-lg shadow hover:bg-black transition font-sans">
            Explore
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Bannerabnvk;
