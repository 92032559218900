import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import About from './pages/About';
import Pricing from './pages/Pricing';
import ContactSection from './pages/ContactSection';
import StayTuned from './pages/StayTuned';
import ProductsSection from './pages/ProductsSection';
import Careers from './pages/Careers';

const root = ReactDOM.createRoot(document.getElementById('root'));

let nexkitesRoute = createBrowserRouter(
  [
    {
      path: '/',
      element: <App />
    },
    {
      path: 'about-us',
      element: <About />
    },
    {
      path: 'pricing',
      element: <Pricing />
    },
    {
      path: 'support',
      element: <ContactSection />
    },
    {
      path: 'services',
      element: <ProductsSection />
    },
    {
      path: 'products',
      element: <StayTuned />
    },
    {
      path: 'careers',
      element: <Careers />
    },
    {
      path: 'stay-tuned',
      element: <StayTuned />
    }


  ]
);

root.render(
  <React.StrictMode>

    <RouterProvider router={nexkitesRoute} />


  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
