import React, { useEffect, useState } from 'react';

const ProgressSlider = () => {
    const [scrollProgress, setScrollProgress] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const docHeight = document.documentElement.scrollHeight;
            const winHeight = window.innerHeight;
            const totalHeight = docHeight - winHeight;
            const progress = (scrollTop / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="fixed bottom-5 right-5 z-50 flex flex-col items-center">
            <div
                className="relative w-12 h-12"
                onClick={handleClick}
            >
                <div
                    className="absolute w-full h-full rounded-full border-4 border-cyan-500 transition-all duration-300 ease-in-out"
                    style={{
                        background: `conic-gradient(cyan 0%, cyan ${scrollProgress}%, lightgray ${scrollProgress}%, lightgray 100%)`,
                        boxShadow: '0 0 10px rgba(0, 255, 255, 0.5)',
                    }}
                />
                <span className="absolute inset-0 flex justify-center items-center text-violet-600 font-bold">↑</span>
            </div>

        </div>
    );
};

export default ProgressSlider;
