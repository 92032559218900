import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintBrush, faRocket, faLaptopCode, faChartLine } from '@fortawesome/free-solid-svg-icons';
import BMimg from "../assets/EditorAru/EditorAruWebpageSnap.png";
import { Link } from 'react-router-dom';

const EditorAru = () => {
  return (
    <div className="relative bg-gray-100 p-6 md:p-10 rounded-2xl shadow-lg flex flex-col md:flex-row items-center my-10 mx-auto">
      {/* Left Section - Text */}
      <div className="w-full md:w-1/2 mb-6 md:mb-0">
        <h4 className="uppercase text-sm text-gray-600 font-semibold">
          Tailored for Your Business
        </h4>
        <h1 className="text-3xl md:text-4xl font-semibold text-gray-800 mt-2">
          Fully Customized Web Design for Your Unique Needs.
        </h1>
        <p className="text-gray-600 mt-4 p-1">
          Empower your business with a bespoke website crafted to align with your goals and vision. From design to deployment, we deliver excellence.
        </p>

        {/* Features */}
        <ul className="mt-4 space-y-2 text-gray-600">
          <li className="flex items-center">
            <FontAwesomeIcon icon={faPaintBrush} className="mr-2" /> Unique and Creative Designs
          </li>
          <li className="flex items-center">
            <FontAwesomeIcon icon={faRocket} className="mr-2" /> Optimized for Performance
          </li>
          <li className="flex items-center">
            <FontAwesomeIcon icon={faLaptopCode} className="mr-2" /> Seamless Development Process
          </li>
          <li className="flex items-center">
            <FontAwesomeIcon icon={faChartLine} className="mr-2" /> Data-Driven Growth Strategies
          </li>
        </ul>

        {/* Button */}
        <Link to={'/portfolio'}>
          <button className="mt-6 px-5 py-3 bg-black text-white font-800 rounded-lg shadow hover:bg-black transition">
            <Link to={'/services'}>View Our Work</Link>
          </button>
        </Link>
      </div>

      {/* Right Section - Image */}
      <div className="w-full md:w-1/2 relative">
        {/* Background Shapes */}
        <div className="absolute inset-0 flex justify-end">
          <div className="bg-purple-600 w-1/4 h-full rounded-r-xl"></div>
          <div className="bg-[#FAF1DC] w-3/4 h-full rounded-r-[50px]"></div>
        </div>

        {/* Image Placeholder */}
        <div className="relative z-10 p-4">
          <img
            src={BMimg}
            alt="Custom web design illustration"
            className="rounded-xl object-cover shadow-lg w-full h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default EditorAru;
