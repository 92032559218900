import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const GetInTouch = () => {
    const navigate = useNavigate(); // Initialize navigate

    return (
        <section className="relative bg-gradient-to-r from-[#fcf5e5] to-[#e0f7fa] rounded-xl text-gray-600 h-auto py-20 flex items-center justify-center mt-10 shadow-lg">
            {/* Banner Content */}
            <div className="relative z-10 text-center px-4 sm:px-6 lg:px-12 max-w-4xl">
                {/* Main Heading */}
                <h1 className="text-4xl md:text-5xl font-extrabold leading-tight mb-4 md:mb-6 text-cyan-600">
                    Welcome to Nexkites! <span className="text-cyan-500">...</span>
                </h1>

                {/* Motivational Subtitle */}
                <h3 className="text-lg md:text-xl leading-tight mb-8">
                    Embark on a journey towards excellence. Discover opportunities that align with your passions!
                </h3>

                {/* Call to Action */}
                <p className="text-md md:text-lg mb-4">
                    Ready to soar higher? 
                    <span className="font-semibold text-gray-800"> Join us today and start your adventure!</span>
                </p>

                {/* Call to Action Button */}
                <button
                    onClick={() => navigate('/services')} // Navigate to services page on click
                    className="bg-black text-white rounded-md px-6 py-3 hover:bg-black transition font-300"
                >
                    Get Started
                </button>
            </div>
        </section>
    );
};

export default GetInTouch;
