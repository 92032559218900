import React, { useState } from 'react';
import Footer from '../constants/Footer';
import Navbar from '../constants/Navbar';
import contactimg from "../assets/logo/Nexkites-kite.png";  // Add your image for consistency
import ProgressSlider from '../constants/ProgressSlider';

const StayTuned = () => {
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        phone: "",
        jobId: "",
        linkedInUrl: "",
        portfolioUrl: "",
    });
    
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [error, setError] = useState("");

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData((prev) => ({ ...prev, [name]: value }));
    };

    // Submit Data to Firebase
    const submitData = async (event) => {
        event.preventDefault();
        const { name, email, phone, jobId, linkedInUrl, portfolioUrl } = userData;

        // Validate if all mandatory fields are filled
        if (!name || !email || !phone || !jobId || !linkedInUrl) {
            setError("Please fill in all mandatory fields.");
            return;
        }
        
        const formData = {
            name,
            email,
            phone,
            jobId,
            linkedInUrl,
            portfolioUrl: portfolioUrl || "N/A",
            submittedAt: new Date().toISOString(),
        };

        try {
            const res = await fetch(
                'https://nexkites-38efb-default-rtdb.firebaseio.com/jobsubmissions.json', 
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                }
            );

            if (res.ok) {
                // Reset the form and show success modal
                setUserData({
                    name: "",
                    email: "",
                    phone: "",
                    jobId: "",
                    linkedInUrl: "",
                    portfolioUrl: "",
                });
                setShowSuccessModal(true);
                setError("");  // Clear any error message
            } else {
                setError("Failed to submit. Please try again.");
            }
        } catch (error) {
            console.error("Error submitting data:", error);
            setError("An error occurred while submitting your data.");
        }
    };

    // Close the success modal
    const closeModal = () => {
        setShowSuccessModal(false);
    };

    return (
        <>
            <Navbar className="mb-10" />
            <section className="relative bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)] rounded-xl text-gray-600 h-auto py-16 mt-10 md:mt-28 shadow-lg selection:bg-cyan-300 selection:text-cyan-900">
                <div className="flex flex-col items-center justify-center max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-12 space-y-6">
                    {/* Image at the top */}
                    <img src={contactimg} alt="Nexkites Logo" className="w-32 md:w-48 h-auto object-contain" />
                    
                    <div className="text-center md:text-left">
                        <h1 className="text-3xl md:text-4xl text-center font-extrabold leading-tight mb-4 md:mb-6 text-cyan-600">
                            Shape Your Future with Nexkites!
                        </h1>
                        <h3 className="text-md md:text-lg leading-tight mb-8 break-words">
                            Your potential is limitless! The Nexkites Team will reach out with exciting career opportunities soon.
                        </h3>
                    </div>

                    <div className="flex-1 max-w-md w-full">
                        <form className="space-y-6" onSubmit={submitData}>
                            <InputField
                                id="name"
                                label="Name"
                                value={userData.name}
                                placeholder="Enter your name"
                                onChange={handleInputChange}
                                name="name"
                                required
                            />
                            <InputField
                                id="email"
                                type="email"
                                label="Email Address"
                                value={userData.email}
                                placeholder="Enter your email"
                                onChange={handleInputChange}
                                name="email"
                                required
                            />
                            <InputField
                                id="phone"
                                type="tel"
                                label="Phone Number"
                                value={userData.phone}
                                placeholder="Enter your phone number"
                                onChange={handleInputChange}
                                name="phone"
                                required
                            />
                            <InputField
                                id="jobId"
                                label="Job ID"
                                value={userData.jobId}
                                placeholder="Enter the Job ID"
                                onChange={handleInputChange}
                                name="jobId"
                                required
                            />
                            <InputField
                                id="linkedInUrl"
                                label="LinkedIn URL"
                                value={userData.linkedInUrl}
                                placeholder="Enter your LinkedIn profile URL"
                                onChange={handleInputChange}
                                name="linkedInUrl"
                                required
                            />
                            <InputField
                                id="portfolioUrl"
                                label="Portfolio URL (Optional)"
                                value={userData.portfolioUrl}
                                placeholder="Enter your portfolio URL (optional)"
                                onChange={handleInputChange}
                                name="portfolioUrl"
                            />
                            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                            <button
                                type="submit"
                                className="bg-black text-white rounded-md px-6 py-3 hover:bg-black transition w-full"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </section>

            {/* Success Modal */}
            {showSuccessModal && (
                <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                        <h2 className="text-2xl font-bold text-green-600 mb-4">Success!</h2>
                        <p className="text-gray-700">The Nexkites Team will be in touch with you shortly. Stay tuned!</p>
                        <button
                            onClick={closeModal}
                            className="mt-4 px-4 py-2 bg-black text-white rounded-md hover:bg-black transition"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

            <Footer />
            <ProgressSlider/>
        </>
    );
};

const InputField = ({ id, type = "text", label, value, placeholder, onChange, name, required }) => (
    <div className="flex flex-col">
        <label htmlFor={id} className="text-sm font-medium text-gray-700 mb-2">
            {label}
        </label>
        <input
            id={id}
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            name={name}
            className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-cyan-400 w-full"
            required={required}
        />
    </div>
);

export default StayTuned;
