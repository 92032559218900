import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from "../assets/logo/NexkitesSvg.png";
import { HiMenu, HiX } from 'react-icons/hi';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation(); // Get the current path

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // Define your menu items
    const menuItems = [
        { name: 'Services', path: '/services' },
        { name: 'Pricing', path: '/pricing' },
        { name: 'About Us', path: '/about-us' },
        { name: 'Careers', path: '/careers' },
        { name: 'Support', path: '/support' }
    ];

    // Function to check if the current route is active
    const isActive = (path) => location.pathname === path;

    // Scroll to top when the location changes
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, [location]); // Run whenever the location (i.e., route) changes

    return (
        <nav className="bg-white fixed top-0 left-0 w-full py-3 shadow-md flex items-center px-6 lg:px-12 z-50 rounded-b-xl">
            {/* Logo Section */}
            <div className="flex items-center w-1/2 md:w-1/3">
                <Link to="/">
                    <img src={logo} alt="Logo" className="h-12" />
                </Link>
            </div>

            {/* Desktop Menu */}
            <div className="hidden md:flex justify-center w-1/2 md:w-2/3 items-center space-x-8 text-md font-600">
                {menuItems.map(item => (
                    <Link
                        key={item.name}
                        to={item.path}
                        className={`text-black hover:text-cyan-400 ${isActive(item.path) ? 'text-cyan-500' : ''}`}
                    >
                        {item.name}
                    </Link>
                ))}
            </div>

            {/* Mobile Hamburger Button */}
            <div className="md:hidden flex items-center ml-auto">
                <button 
                    onClick={toggleMenu} 
                    className="text-black hover:text-cyan-400" 
                    aria-expanded={isOpen}
                    aria-label={isOpen ? "Close menu" : "Open menu"}
                >
                    {isOpen ? <HiX size={24} /> : <HiMenu size={24} />}
                </button>
            </div>

            {/* Mobile Dropdown Menu */}
            {isOpen && (
                <div className="absolute top-14 right-4 bg-white shadow-md rounded-md w-48 md:hidden transition duration-200 ease-in-out">
                    <ul className="flex flex-col">
                        {menuItems.map(item => (
                            <li key={item.name}>
                                <Link
                                    to={item.path}
                                    className="block px-4 py-2 text-black hover:text-cyan-400"
                                    onClick={toggleMenu}
                                >
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
