import React from 'react';
import './index.css';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Navbar from './constants/Navbar';
import ProgressSlider from './constants/ProgressSlider';
import Footer from './constants/Footer';
import GetInTouch from './components/GetInTouch';
import BannerMiddle from './components/BannerMiddle';
import Bannerabnvk from './components/Bannerabnvk';
import BannerApp from './components/BannerApp';
import BannerVideo from './components/BannerVideo';
import EditorAru from './components/EditorAru';



function App() {
  return (
    <div className="overflow-x-hidden antialiased selection:bg-cyan-300 selection:text-cyan-900 relative">
      {/* Background Layer */}
      <div className="absolute top-0 z-[-2] h-screen w-screen bg-white bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)]"></div>

      {/* Main Container */}
      <div className="container bg-[radial-gradient(100%_50%_at_50%_0%,rgba(0,163,255,0.13)_0,rgba(0,163,255,0)_50%,rgba(0,163,255,0)_100%)] mx-auto py-8 px-4 md:px-6 lg:px-8">
        <Navbar />

        <div className="space-y-8">
          <BannerVideo/>
          <EditorAru/>
          <BannerApp />
          <Bannerabnvk />
          <BannerMiddle />
          <GetInTouch />
        </div>
      </div>

      <ProgressSlider /> {/* Add ProgressSlider here */}
      <Footer />
    </div>
  );
}

export default App;


