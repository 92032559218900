import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import BackgroundVideo from "../assets/videos/EditorAru Webpage.mp4"; // Import your video file

const BannerVideo = () => {
    const navigate = useNavigate(); // Initialize navigate

    return (
        <section className="relative h-[550px] my-12 flex items-center justify-center p-5">
            {/* Video Background */}
            <video
                autoPlay
                loop
                muted
                className="absolute inset-0 w-full h-full object-cover rounded-xl"
            >
                <source src={BackgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {/* Background Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-30 rounded-xl"></div>

            {/* Banner Content */}
            <div className="relative z-10 text-center px-6 lg:px-12 max-w-4xl text-white">
                <h1 className="text-4xl md:text-5xl font-semibold leading-tight mb-6">
                    <span>Elevate Your Brand with</span>
                    <br />
                    <span className="text-cyan-300 font-extrabold">Next-Gen</span>
                    <br />
                    <span>Web Designs!</span>
                </h1>

                {/* Get Started Button */}
                <button
                    onClick={() => navigate('/services')} // Navigate to services page on click
                    className="mt-6 bg-cyan-400 text-white rounded-md px-6 py-3 hover:bg-[#d83299] transition duration-300"
                >
                    Get Started
                </button>
            </div>
        </section>
    );
};

export default BannerVideo;
